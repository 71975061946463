//TODO: Add view showing which notifications the user is on

import { useUser, useUserDispatch } from "../store/UserContext";
import { handleDeleteRecipient } from "../services/recipient";

export default function RecipientsList({ setShowForm, setSelectedRecipient }) {
  const user = useUser();
  const dispatch = useUserDispatch();
  const deleteRecipientClicked = (e, recipient_id) => {
    e.stopPropagation(); // todo fix this, it's not working
    const { id, token } = user;
    handleDeleteRecipient({ recipient_id, user_id: id, token, dispatch })
  }

  const selectRecipient = (recipient_id) => {
    const recipient = user?.recipients?.find(r => r._id === recipient_id)
    setSelectedRecipient({ ...recipient });
    setShowForm(true)
  }

  return (
    <div className="px-4 pt-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Recipients</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the recipeints you've saved.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => setShowForm(true)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Recipient
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Cell
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    </th>
                    {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {user?.recipients?.map((recipient, i) => (
                    <tr
                      key={i}
                      onClick={() => selectRecipient(recipient._id)}
                      className="hover:bg-blue-100 cursor-pointer"
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {recipient?.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{recipient?.cell}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{recipient.email}</td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{recipient.dateAdded ? 'true' : 'false'}</td> */}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                        <p onClick={(e) => deleteRecipientClicked(e, recipient?._id)} className="text-red-600 hover:underline hover:text-red-600 hover:cursor-pointer">
                          Delete
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React, {useState, useEffect} from "react"
import {
  usePlaidLink,
} from 'react-plaid-link';
import { useUser } from "../store/UserContext";
const base_url = process.env.REACT_APP_API_URL;

const getConfig  = (token, setPublicToken, setAccountInfo, setAccountName) => { 
  return {
    onSuccess: (public_token, metadata) => {
      console.log('onSuccess')
      console.log('public_token: ', public_token);
      console.log('metadata: ', metadata);
      console.log('account nae: ', metadata?.institution?.name);
      setAccountName(metadata?.institution?.name)
      setPublicToken(public_token);
      setAccountInfo({
        accountName: metadata?.institution?.name,
        accounts: [...metadata?.accounts]
      })
    },
    onExit: (err, metadata) => {
      console.log('onExit')
      console.log('err: ', err);
      console.log('metadata: ', metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log('onEvent')
      console.log('eventName: ', eventName);
      console.log('metadata: ', metadata);
    },
    token,
    //required for OAuth; if not using OAuth, set to null or omit:
  }
};

const generateLink = async (setLinkToken, user) => {
  const response = await fetch(base_url +'/api/plaid/create_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${user?.token}`
    },
    body: JSON.stringify({
      user_id: user?.id,
    })

  })

  if(!response.ok){
    setLinkToken('unable to set link token')
  }

  const data = await response.json();
  if(!data.error){
    setLinkToken(data?.plaidLink?.link_token);
  }
}
const saveAccount = async (publicToken, user, accountName) => {
  const response = await fetch(base_url +'/api/plaid/save_account', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${user?.token}`
    },
    body: JSON.stringify({
      user_id: user?.id,
      public_token: publicToken,
      account_name: accountName
    })
  })

  if(!response.ok){
    alert('unable to save account')
  }

  await response.json();
  // authContext?.setCurrentUser({
  //   ...authContext?.currentUser,
  //   accounts: data?.accounts
  // });
  alert('account saved ++ this is borken')
}
const generateAccessToken = async (publicToken, setAccessToken) => {
  console.log({publicToken});
  const response = await fetch(base_url +'/api/plaid/exchange_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      public_token: publicToken
    })
  })

  if(!response.ok){
    setAccessToken('unable to set access token')
  }

  const data = await response.json();
  console.log('look at this data:', data);
  console.log('generateAccessToken response: ', data)
  setAccessToken({accessToken: data?.access_token, itemId: data?.item_id });
}
// const getTransactions = async (accessToken,transactions, setTransactions) => {
//   console.log({accessToken});
//   const response = await fetch(base_url +'/api/plaid/transactions', {
//     method: 'POST',
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify({
//       access_token: accessToken 
//     })
//   })

//   if(!response.ok){
//     setTransactions(['unable to set access token'])
//   }

//   const data = await response.json();
//   console.log('getTransactions response: ', data?.transactions)
//   console.log('transactions before: ', transactions)
//   setTransactions([...data?.transactions]);
//   console.log('transactions after: ', transactions)
// }


// const onSuccess = (public_token) => {
//   console.log({public_token})
// }

// const {open, ready} = usePlaidLink({token: "", onSuccess})

const AccountLink = () => {
const user = useUser();
const [accountName, setAccountName] = useState(null);
const [linkToken, setLinkToken] = useState(null);
const [publicToken, setPublicToken] = useState(null)
const [accessData, setAccessData] = useState({accessToken: null,itemId: null})
const [transactions] = useState([]);
const [accountInfo, setAccountInfo] = useState({
  accountName: '',
  accounts: []
});

const handleChange = (e) => {
  setAccountName(e.target.value)
}

const config = getConfig(linkToken, setPublicToken, setAccountInfo, setAccountName);
const { open, ready } = usePlaidLink(config);

useEffect(() => {
  console.log('generateLink link useEffect running')
  const initGenerateLink = async () => {
    await generateLink(setLinkToken, user)
  }

  initGenerateLink();
}, [user])

useEffect(() => {
  if(!ready) {
    console.log('not ready');
    return;
  }
  
  try {
    open();
  } catch(error){
    console.log('error in open: ', error)
  }
}, [ready, open])
  
return (
    <div>
      <h1>Testing</h1>
      {/* <button onClick={() => generateLink(setLinkToken)}>1. generate link</button><br/> */}
      {ready && <button onClick={() => open()}>2. connect new account</button>}
      <button onClick={() => generateAccessToken(publicToken, setAccessData)}>3. generate accessToken</button><br/>
      {/* <button onClick={() => getTransactions(accessData.accessToken, transactions, setTransactions)}>4. get transactions</button><br/> */}
       <input id="accountName" name="accountName" onChange={handleChange} value={accountName} type="text"></input>
      <button onClick={() => saveAccount(publicToken, user, accountName)}> Save Account</button><br/>
      <p>Account Name: {accountName}</p>
      <p>Link Token: {linkToken}</p>
      <p>Public Token: {publicToken}</p>
      <p>Access Data: </p>
      <p>...  Token: {accessData.accessToken}</p>
      <p>... Id: {accessData.itemId}</p>
      <p>Ready : {ready}</p>
      <p>Account Name: {accountInfo?.accountName}</p>
      <div>{accountInfo?.accounts?.length && accountInfo?.accounts?.map(account => {
        return (<p key={account?.id}>{account?.name}, {account?.subtype}, {account?.id}</p>)
      })}</div>
      <p>Transactions : {!!transactions?.length}</p>
      <div>{transactions?.length && transactions.map(transaction => {
        return (<p key={transaction?.amount}>{transaction?.merchant_name}, {transaction.amount}, {transaction.date}</p>)
      })}</div>

    </div>
  )
}

export default AccountLink 

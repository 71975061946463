import { base_url } from ".";


const createLink = async (userId, accountId, accessToken, authContext) => {
  const response = await fetch(base_url + '/api/plaid/create_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + authContext?.currentUser?.token

    },
    body: JSON.stringify({
      user_id: userId,
      account_id: accountId,
      access_token: accessToken
    })
  })

  if (!response.ok) {
    alert('unable to create link')
  }

  const data = await response.json();
  return data;
}

const deleteLink = async (userId, accountId) => {
  const response = await fetch(base_url + '/api/plaid/delete_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      user_id: userId,
      account_id: accountId
    })
  })

  if (!response.ok) {
    alert('unable to delete link')
  }

  const data = await response.json();
  alert('account deleted')
  return data?.accounts;
}


const greenListAccount = async (access_token, user, link_id, account_id) => {
  const response = await fetch(base_url + '/api/account/green_list', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + user?.token
    },
    body: JSON.stringify({
      access_token,
      user_id: user?.currentUser?.id,
      link_id,
      account_id
    })
  })

  if (!response.ok) {
    alert('unable to greenlist account ')
    return;
  }

  const data = await response.json();
  console.log({ data })
  alert(data?.message)
  return data;
}
const redListAccount = async (access_token, authContext, link_id, account_id) => {
  const response = await fetch(base_url + '/api/account/redlist_account', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + authContext?.currentUser?.token
    },
    body: JSON.stringify({
      access_token,
      user_id: authContext?.currentUser?.id,
      link_id,
      account_id
    })
  })

  if (!response.ok) {
    alert('unable to redlist account ')
    return;
  }

  const data = await response.json();
  console.log({ data })
  alert(data?.message)
  return data;
}
const getAccounts = async (access_token, user) => {
  const response = await fetch(base_url + '/api/plaid/accounts', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + user?.token
    },
    body: JSON.stringify({
      access_token,
      user_id: user?.id,
    })
  })

  if (!response.ok) {
    alert('unable to get accounts')
  }

  const data = await response.json();
  console.log({ data })
  return data?.accounts
}

const getTransactions = async (access_token, account_id, user) => {
  const response = await fetch(base_url + '/api/plaid/transactions', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + user?.token
    },
    body: JSON.stringify({
      access_token,
      account_id,
      user_id: user?.currentUser?.id,
    })
  })

  if (!response.ok) {
    const data = await response.json();
    let errorMessage = data?.error?.message;
    if (errorMessage?.includes('Refresh')) {
      alert('Please refresh your connection to this account.');
      //: TODO add button to try to refresh in alert modal
    } else {
      alert('unable to get transactions')
    }

    return [];
  }

  const data = await response.json();
  console.log(data.error)
  return data.transactions
}

const getAllTransactions = async ({ access_tokens, user_id, token }) => {
  const response = await fetch(base_url + '/api/plaid/transaction_total', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify({
      access_tokens,
      user_id
    })
  })

  if (!response.ok) {
    alert('unable to get all transactions')

    return [];
  }

  const data = await response.json();
  console.log(data.error)
  return data.transactions
}


export {
  deleteLink,
  getTransactions,
  getAllTransactions,
  getAccounts,
  redListAccount,
  greenListAccount,
  createLink
}
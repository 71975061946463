import React, { useState } from "react"
import { useUser } from "../store/UserContext";
import {
  usePlaidLink,
} from 'react-plaid-link';
import { base_url } from "../network";
import { testCronJobFunction, createCronJob } from "../network/cron";

const testCronFunction = (user) => {
  const { id, token } = user;
  testCronJobFunction({ user_id: id, token })
}
const createCron = (user) => {
  const { id, token } = user;
  createCronJob({ user_id: id, token })
}

const getConfig = (token, setPublicToken, setAccountInfo) => {
  return {
    onSuccess: (public_token, metadata) => {
      console.log('onSuccess')
      console.log('public_token: ', public_token);
      console.log('metadata: ', metadata);
      console.log('account nae: ', metadata?.institution?.name);
      setPublicToken(public_token);
      setAccountInfo({
        accountName: metadata?.institution?.name,
        accounts: [...metadata?.accounts]
      })
    },
    onExit: (err, metadata) => {
      console.log('onExit')
      console.log('err: ', err);
      console.log('metadata: ', metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log('onEvent')
      console.log('eventName: ', eventName);
      console.log('metadata: ', metadata);
    },
    token,
    //required for OAuth; if not using OAuth, set to null or omit:
  }
};

const generateLink = async (setLinkToken, user) => {
  const response = await fetch(base_url + '/api/plaid/create_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${user?.token}`
    },
    body: JSON.stringify({
      user_id: user?.id,
    })

  })

  if (!response.ok) {
    setLinkToken('unable to set link token')
  }

  const data = await response.json();
  console.log('generateLink response', data);
  setLinkToken(data?.plaidLink?.link_token);
}
const generateAccessToken = async (publicToken, setAccessToken) => {
  console.log({ publicToken });
  const response = await fetch(base_url + '/api/plaid/exchange_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      public_token: publicToken
    })
  })

  if (!response.ok) {
    setAccessToken('unable to set access token')
  }

  const data = await response.json();
  console.log('look at this data:', data);
  console.log('generateAccessToken response: ', data)
  setAccessToken({ accessToken: data?.access_token, itemId: data?.item_id });
}

const getTransactions = async (accessToken, transactions, setTransactions) => {
  console.log({ accessToken });
  const response = await fetch(base_url + '/api/plaid/transactions', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      access_token: accessToken
    })
  })

  if (!response.ok) {
    setTransactions(['unable to set access token'])
  }

  const data = await response.json();
  console.log('getTransactions response: ', data?.transactions)
  console.log('transactions before: ', transactions)
  setTransactions([...data?.transactions]);
  console.log('transactions after: ', transactions)
}


// const onSuccess = (public_token) => {
//   console.log({public_token})
// }

// const {open, ready} = usePlaidLink({token: "", onSuccess})

const Testing = () => {
  const user = useUser();

  const [linkToken, setLinkToken] = useState(null);
  const [publicToken, setPublicToken] = useState('public-development-2b545286-af90-4c21-b9a7-604f9b589527');
  const [accessData, setAccessData] = useState({ accessToken: 'access-development-af2ffa5a-3b3f-4550-b617-f64eca84f49a', itemId: 'NNB8KPLrn6uk8d8LEY7qUoNnO6dwZnURx64EZ' });
  const [transactions, setTransactions] = useState([]);
  const [accountInfo, setAccountInfo] = useState({
    accountName: '',
    accounts: []
  });
  const config = getConfig(linkToken, setPublicToken, setAccountInfo);
  const { open, ready } = usePlaidLink(config);

  return (
    <div>
      <h1>Testing</h1>
      <button onClick={() => generateLink(setLinkToken, user)}>1. generate link</button><br />
      <button onClick={() => open()}>2. connect to plaid</button><br />
      <button onClick={() => generateAccessToken(publicToken, setAccessData)}>3. generate accessToken</button><br />
      <button onClick={() => getTransactions(accessData.accessToken, transactions, setTransactions)}>4. get transactions</button><br />
      <p>Link Token: {linkToken}</p>
      <p>Public Token: {publicToken}</p>
      <p>Access Data: </p>
      <p>...  Token: {accessData.accessToken}</p>
      <p>... Id: {accessData.itemId}</p>
      <p>Ready : {ready ? 'true' : 'false'}</p>
      <p>Account Name: {accountInfo?.accountName}</p>
      <div>{accountInfo?.accounts?.length && accountInfo?.accounts?.map(account => {
        return (<p>{account?.name}, {account?.subtype}, {account?.id}</p>)
      })}</div>
      <p>Transactions : {!!transactions?.length}</p>
      <div>{transactions?.length && transactions.map(transaction => {
        return (<p>{transaction?.merchant_name}, {transaction.amount}, {transaction.date}</p>)
      })}</div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          type="button"
          onClick={() => testCronFunction(user)}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
        >
          Test Cron
        </button>
      </div>

      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          type="button"
          onClick={() => createCron(user)}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
        >
          Create Cron
        </button>
      </div>

    </div>
  )
}

export default Testing 

import { base_url } from ".";

const updateAuthRefreshNeeded = async (user, account_id, auth_refresh_needed) => {
  let URL = `${base_url}/api/account/update_auth_refresh`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${user?.token}`,
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    },
    body: JSON.stringify({
      account_id,
      auth_refresh_needed
    })
  });
  const data = await response.json();
  return data;
}

export { updateAuthRefreshNeeded }

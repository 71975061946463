import React, {useState} from "react"
import NotificationList from '../components/NotificationList'
import NotificationForm from "../components/NotificationForm";

export default function Notifications(){
const [showForm, setShowForm]  = useState(false)
const [selectedNotification, setSelectedNotification]  = useState(null)
  
return (
    <div>
     <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
      {showForm
        ? <NotificationForm
            setShowForm={setShowForm}
            selectedNotification={selectedNotification}
            setSelectedNotification={setSelectedNotification}
        />
        : <NotificationList 
            setShowForm={setShowForm}
            setSelectedNotification={setSelectedNotification}
          />
      }

      </div>

    </div>
  )
}

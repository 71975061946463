import { base_url } from ".";
const URL = `${base_url}/api/emailList`;
const captureEmail = async (args) => {
  const { email } = args;
  const response = await fetch(`${URL}/capture`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email
    })
  });
  const data = await response.json();
  return data;
}

export { captureEmail }

import { getAllTransactions } from "../network/plaid";

const getConfig = (token, setPublicToken, setAccountInfo, setRefreshSuccessful) => {
  return {
    onSuccess: (public_token, metadata) => {
      console.log('onSuccess')
      console.log('public_token: ', public_token);
      console.log('metadata: ', metadata);
      console.log('account nae: ', metadata?.institution?.name);
      setRefreshSuccessful(true)
      setPublicToken(public_token);
      setAccountInfo({
        accountName: metadata?.institution?.name,
        accounts: [...metadata?.accounts]
      })
    },
    onExit: (err, metadata) => {
      console.log('onExit')
      console.log('err: ', err);
      console.log('metadata: ', metadata);
    },
    onEvent: (eventName, metadata) => {
      console.log('onEvent')
      console.log('eventName: ', eventName);
      console.log('metadata: ', metadata);
    },
    token,
    //required for OAuth; if not using OAuth, set to null or omit:
  }
};

// const generateLink = async () => {
//   const response = await fetch('/api/plaid/create_link', {
//     method: 'POST',
//   })

//   if(!response.ok){
//     return null;
//   }

//   const data = await response.json();
//   console.log('generateLink response', data);
//   return data.plaidLink.link_token
// }
const generateAccessToken = async (publicToken, setAccessToken) => {
  const response = await fetch('/api/plaid/exchange_link', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      public_token: publicToken
    })
  })

  if (!response.ok) {
    setAccessToken('unable to set access token')
  }

  const data = await response.json();
  console.log('look at this data:', data);
  console.log('generateAccessToken response: ', data)
  setAccessToken({ accessToken: data?.access_token, itemId: data?.item_id });
}

const handleGetAllTransactions = async (args) => {
  const { access_tokens, user_id, token, dispatch } = args;
  Promise.resolve().then(async () => {
    const result = await getAllTransactions({ access_tokens, user_id, token })
    const { user, message } = result;
    alert(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    alert(error);
  })

}

// const getTransactions = async (accessToken,transactions, setTransactions) => {
//   console.log({accessToken});
//   const response = await fetch('/api/plaid/transactions', {
//     method: 'POST',
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify({
//       access_token: accessToken 
//     })
//   })

//   if(!response.ok){
//     setTransactions(['unable to set access token'])
//   }

//   const data = await response.json();
//   console.log('getTransactions response: ', data?.transactions)
//   console.log('transactions before: ', transactions)
//   setTransactions([...data?.transactions]);
//   console.log('transactions after: ', transactions)
// }


// const initAccountLink = async (userId) => {
//   const publicToken = null; 
//   const setPublicToken = (token) => {
//     publicToken = token;
//   }
//   const accountInfo = null; 
//   const setAccountInfo = (info) => {
//     accountInfo = info;
//   }
//   const accessData = null; 
//   const setAccessData = (data) => {
//     accessData = data;
//   }

//   const linkToken = await generateLink();
//   // const config = await getConfig(linkToken, setPublicToken, setAccountInfo)
//   // const {open, exit, ready} = usePlaidLink(config)
//   // open();

//   // 30min to use public token to generate access token
//   // new func to save account, save access token
//   // generateAccessToken(publicToken, setAccessData);

// }

export { getConfig, generateAccessToken, handleGetAllTransactions }


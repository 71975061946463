import { base_url } from ".";
const URL = `${base_url}/api/subscription`;
const createSubscriptionSession = async ({ token, stripe_customer_id }) => {
  const response = await fetch(`${URL}/manage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify({
      stripe_customer_id
    })

  });
  return await response.json();

}
const createCheckoutSession = async ({ token, user_id }) => {
  const response = await fetch(`${URL}/checkout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify({
      user_id
    })
  });
  return await response.json();
}
export { createCheckoutSession, createSubscriptionSession }

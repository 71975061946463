import { base_url } from ".";
const URL = `${base_url}/api/bank`; 

const setAuthRefresh = async (args) => {
  console.log('running setAuthRefresh', args)

  const {
    user_id,
    bank_id,
    token
  } = args;
  
  const response = await fetch(`${URL}/set_auth_refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      bank_id,
      user_id
    })
  });
  return await response.json();
}

const createBankLink = async (args) => {
  console.log('running createBankLink', args)

  const {
    user_id,
    public_token,
    bank_name,
    bank_accounts,
    token
  } = args;
  
  const response = await fetch(`${URL}/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      user_id,
      public_token,
      bank_name,
      bank_accounts
    })
  });
  return await response.json();
}


const deleteBankLink = async (args) => {
  const {bank_id, user_id, token} = args;

  const response = await fetch(`${URL}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`

    },
    body: JSON.stringify({
      user_id,
      bank_id
    })
  });
  return await response.json();
}

export {deleteBankLink, createBankLink, setAuthRefresh}
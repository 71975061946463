import React from "react"
import { CalendarIcon, BanknotesIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'

const items = [
  {
    name: 'Link a bank',
    description: 'After linking a bank account, you can select accounts from that bank to track daily, weekly, and monthly spending.',
    href: '/banks',
    iconColor: 'bg-emerald-500',
    icon: BanknotesIcon,
  },
  {
    name: 'Add a notification recipient',
    description: 'Enter the name and cell number of those you would like to send spending updates.',
    href: '/recipients',
    iconColor: 'bg-emerald-500',
    icon: PaperAirplaneIcon,
  },
  {
    name: 'Schedule the notification',
    description: 'Add your linked bank accounts and recipients to the notification, start getting updates today.',
    href: '/notifications',
    iconColor: 'bg-emerald-500',
    icon: CalendarIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {

  return (
    <>
      <div className="mx-auto max-w-lg pt-16">
        <h2 className="text-base font-semibold leading-6 text-gray-900">Create your first notification</h2>
        <p className="mt-1 text-sm text-gray-500">Get started by selecting a template or start from an empty project.</p>
        <ul className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
          {items.map((item, itemidx) => (
            <li key={itemidx}>
              <div className="group relative flex items-start space-x-3 py-4">
                <div className="flex-shrink-0">
                  <span
                    className={classNames(item.iconColor, 'inline-flex h-10 w-10 items-center justify-center rounded-lg')}
                  >
                    <item.icon className="h-6 w-6 text-black" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="text-sm font-medium hover:underline text-gray-900">
                    <a href={item.href}>
                      <span className="absolute inset-0 " aria-hidden="true" />
                      {item.name}
                    </a>
                  </div>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <chevronrighticon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-6 flex">
          <a href="/banks" className="text-sm font-medium text-emerald-600 hover:text-emerald-500">
            link a new bank account
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>

    </>
  )
}





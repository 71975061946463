import { createCheckoutSession, createSubscriptionSession } from "../network/subscription"
import { toast } from "react-hot-toast";

const handleManageSubscriptionClicked = ({ token, stripe_customer_id }) => {
  Promise.resolve().then(async () => {
    const { session, message } = await createSubscriptionSession({ token, stripe_customer_id });
    window.open(session.url, '_self')
    toast.success(message)
  }).catch(error => {
    toast.error(error)
  })

}

const handleCreateCheckoutSession = ({ token, user_id }) => {

  Promise.resolve().then(async () => {
    const { session, message } = await createCheckoutSession({ token, user_id });
    window.open(session.url, '_self')
    toast.success(message)
  }).catch(error => {
    toast.error(error)
  })
}

export { handleCreateCheckoutSession, handleManageSubscriptionClicked }
import { base_url } from ".";
const url = `${base_url}/api/cron`


const createCronJob = async (args) => {
  const {user_id, token} = args;
  const response = await fetch(url +'/create', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token

    },
    body: JSON.stringify({
      user_id
    })
  })

  if(!response.ok){
    alert('unable to create cron job')
  }

  const data = await response.json();
  return data;
}

const testCronJobFunction = async (args) => {
  const {user_id, token} = args;
  const response = await fetch(url +'/test', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token

    },
    body: JSON.stringify({
      user_id
    })
  })

  if(!response.ok){
    alert('unable to create link')
  }

  const data = await response.json();
  return data;
}

const getTasks = async (authContext) => {
  const response = await fetch(url +'/get_jobs', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + authContext?.currentUser?.token
    },
    body: JSON.stringify({
      user_id: authContext?.currentUser?.id
    })
  })

  if(!response.ok){
    alert('unable to get jobs')
  }

  const data = await response.json();
  return data;
}

export {createCronJob, testCronJobFunction, getTasks}

import {signin, signup, resetPassword} from "../network/auth"

const handleSignup = async (args) => {
  const {email, password, dispatch, navigate} = args;

  Promise.resolve().then(async() => {
    let user = await signup(email, password);
    console.log({user})
    if(!user){
      alert('error signing up')
      return;
    }
    dispatch({
      type: 'updated',
      user 
    })
    navigate('/dashboard')
  }).catch(error => {
    alert(error);
  })
}

const handleSignin = async (args) => {
  const {email, password, dispatch, navigate} = args;

  Promise.resolve().then(async() => {
    let user = await signin(email, password);
    console.log('signin res', user)
    if(user?.error){
      alert('error signing in')
      return;
   }
    dispatch({
      type: 'updated',
      user 
    });
    navigate('/dashboard')
  }).catch(error => {
    alert(error);
  })
}
const handleResetPassword = async (args) => {
  console.log('handleResetPassword')
  const {new_password, token, user_id, email, dispatch} = args;

  Promise.resolve().then(async() => {
    let user = await resetPassword({
      new_password,
      token,
      user_id,
      email
    });

    if(user?.error){
      alert('error resetting password')
      return;
   }
    dispatch({
      type: 'updated',
      user 
    });
  }).catch(error => {
    alert(error);
  })
}

export {handleSignin, handleSignup, handleResetPassword}
import { toast } from "react-hot-toast";
import { deleteBankLink, createBankLink, setAuthRefresh } from "../network/banks"

const handleSetAuthRefresh = (args) => {
  console.log('in handle set aut hrefrsh')
  const {
    user_id,
    bank_id,
    token,
    dispatch
  } = args;


  Promise.resolve().then(async () => {
    const result = await setAuthRefresh({
      user_id,
      token,
      bank_id
    });
    const { user, message } = result;
    alert(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    alert(error);
  })
}
const handleCreateBankLink = (args) => {
  const {
    user_id,
    public_token,
    bank_name,
    bank_accounts,
    token,
    dispatch
  } = args;


  Promise.resolve().then(async () => {
    const result = await createBankLink({
      user_id,
      public_token,
      bank_name,
      bank_accounts,
      token
    });
    const { user, message } = result;
    toast.success(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    toast.error(error)
  })
}
const handleDeleteBankLink = (args) => {
  // make network call, update state

  console.log({ args })
  const { bank_id, user_id, token, dispatch } = args;

  Promise.resolve().then(async () => {
    const result = await deleteBankLink({ bank_id, user_id, token });
    const { user, message } = result;
    toast.success(message)
    alert(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    toast.error(error)
  })
}

export { handleDeleteBankLink, handleCreateBankLink, handleSetAuthRefresh }

import React from "react";
import {useUser} from "../store/UserContext";
import {Navigate} from "react-router-dom";

const RequireAuth = ({ children }) => {
  const user = useUser();
  
  if (!user?.token) {
    return <Navigate to="/signin" replace={true} />
  }

  return children;
}

export default RequireAuth
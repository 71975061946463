import { base_url } from ".";
const URL = `${base_url}/api/auth`; 

const signin = async (email, password) => {
  const response = await fetch(`${URL}/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      password
    })
  });
  return await response.json();
}

const signup = async (email, password) => {
  const response = await fetch(`${URL}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      password
    })
  });
  return await response.json();
}

const resetPassword = async (args) => {
  console.log({args})
  const {new_password, email, user_id, token} = args
  const response = await fetch(`${URL}/reset_password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`

    },
    body: JSON.stringify({
      email,
      user_id,
      new_password
    })
  });

  const {user, message} = await response.json();
  alert(message)
  return user;
}

export {signin, signup, resetPassword}
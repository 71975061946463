import { base_url } from ".";

const sendTransmission = async (access_token, user) => {
  const response = await fetch(base_url + '/api/twilio/send_transmission', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${user?.token}`

    },
    body: JSON.stringify({
      user_id: user?.id,
      access_token,
      accounts: [...user?.accounts]
    })
  })

  if(!response.ok){
    alert('unable to get transactions')
  }

  const data = await response.json();
  alert('transmission successful')
  return data.message
}

export {sendTransmission}

import { captureEmail } from "../network/emailList";

const handleEmailCapture = (args) => {
  console.log('in handle email capture')
  const {
    email
  } = args;


  Promise.resolve().then(async () => {
    const result = await captureEmail({
      email
    });
    const { message } = result;
    alert(message)
  }).catch(error => {
    alert(error);
  })
}

export { handleEmailCapture }

import { toast } from "react-hot-toast";
import { createNotification, deleteNotification, updateNotification, testNotification } from "../network/notification";

const handleCreateNotification = (args) => {
  const { name, recipients, accounts, user_id, token, dispatch } = args;

  Promise.resolve().then(async () => {
    const result = await createNotification({ name, recipients, accounts, user_id, token })
    const { user, message } = result;
    toast.success(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    toast.error(error)
  })
}

const handleTestNotification = (args) => {
  const { notification_id, name, recipients, accounts, user_id, token } = args;
  Promise.resolve().then(async () => {
    const { message } = await testNotification({ notification_id, name, recipients, accounts, user_id, token })
    toast.success(message)
  }).catch(error => {
    toast.error(error.message)
  })
}
const handleUpdateNotification = (args) => {
  const { notification_id, name, recipients, accounts, user_id, token, dispatch } = args;

  Promise.resolve().then(async () => {
    const result = await updateNotification({ notification_id, name, recipients, accounts, user_id, token })
    const { user, message } = result;
    alert(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    alert(error);
  })
}

const handleDeleteNotification = (args) => {
  const { notification_id, user_id, token, dispatch } = args;

  Promise.resolve().then(async () => {
    const result = await deleteNotification({ notification_id, user_id, token })
    const { user, message } = result;
    toast.success(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    toast.error(error);
  })
}

export { handleCreateNotification, handleDeleteNotification, handleUpdateNotification, handleTestNotification }
import React, {useState} from "react"
import RecipientsList from '../components/RecipientsList'
import RecipientForm from "../components/RecipientForm";

export default function Recipients(){
const [showForm, setShowForm]  = useState(false)
const [selectedRecipient, setSelectedRecipient]  = useState(null)
  
return (
  <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
    {showForm
      ? <RecipientForm
          setShowForm={setShowForm}
          selectedRecipient={selectedRecipient}
          setSelectedRecipient={setSelectedRecipient}
      />
      : <RecipientsList 
          setShowForm={setShowForm}
          setSelectedRecipient={setSelectedRecipient}
        />
    }
  </div>
  )
}

import { BrowserRouter as Router } from 'react-router-dom';
import Main from './Main';
import { UserProvider } from '../store/UserContext';
import { AuthProvider } from '../store/AuthProvider';
import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <AuthProvider>
      <UserProvider>
        <Toaster />
        <Router>
          <Main />
        </Router>
      </UserProvider>
    </AuthProvider>
  )
};

export default App;

import { Routes, Route } from 'react-router-dom'
import { useUser } from "../store/UserContext";
import Notifications from '../containers/Notifications'
import Dashboard from '../containers/Dashboard'
import Recipients from '../containers/Recipients'
import Testing from '../components/Testing'
import Banks from '../containers/Banks'
import Bank from '../components/Bank'
import Account from '../components/Account'
import Welcome from '../containers/Welcome'
import Pricing from '../containers/Pricing'
import Settings from '../containers/Settings'
import AccountLink from '../components/AccountLink'
import AuthForm from '../components/AuthForm'
import AccountDetails from '../components/AccountDetails'
import RequireAuth from '../middleware/RequireAuth'
import Navbar from './Navbar';

export default function Main() {
  const user = useUser()
  return (
    <>
      {user
        ? <>
          <Navbar />
          <RequireAuth>
            <Routes>
              <Route exact path="/testing" element={
                <Testing />
              } />
              <Route exact path="/recipients" element={
                <Recipients />
              } />
              <Route exact path="/settings" element={
                <Settings />
              } />
              <Route exact path="/dashboard" element={
                <Dashboard />
              } />
              <Route exact path="/notifications" element={
                <Notifications />
              } />
              <Route exact path="/banks" element={
                <Banks />
              } />
              <Route exact path="/bank" element={
                <Bank />
              } />
              <Route exact path="/account" element={
                <Account />
              } />
              <Route exact path="/account/link" element={
                <AccountLink />
              } />
              <Route exact path="/account/:itemId" element={
                <AccountDetails />
              } />

            </Routes>
          </RequireAuth>

        </>
        : <Routes>
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/signup" element={<AuthForm form="signup" buttonText="Sign Up!" heading="Sign up to get started" />} />
          <Route exact path="/signin" element={<AuthForm form="signin" buttonText="Sign In!" heading="Sign in to your account" />} />
          <Route exact path="*" element={<Welcome />} />
        </Routes>

      }
    </>
  )
}

import {useState} from "react"
import NewBankForm from "../components/NewBankForm";
import BankList from "../components/BankList";

export default function Banks(){
const [showForm, setShowForm]  = useState(false)
  
return (
    <div>
      <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
      {showForm
        ? <NewBankForm
            setShowForm={setShowForm}
        />
        : <BankList 
            setShowForm={setShowForm}
          />
      }
      </div>
    </div>
  )
}

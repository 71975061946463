import React, {useState, useEffect} from "react"
import {getConfig} from '../services/plaid'
import {useUser} from "../store/UserContext";
import {useParams} from 'react-router-dom'
import {deleteLink, getTransactions, getAccounts, redListAccount, greenListAccount} from "../network/plaid";
import {sendTransmission} from "../network/twilio";
import {updateAuthRefreshNeeded} from "../network/account";
import {useNavigate} from "react-router-dom";
import {
  usePlaidLink,
} from 'react-plaid-link';


const AccountDetails = (props) => {
const  user = useUser();
let { itemId } = useParams();
const navigate = useNavigate();
const [accountInfo, setAccountInfo] = useState(null);
const [transactions, setTransactions] = useState([]);
const [accounts, setAccounts] = useState([]);
const [linkToken] = useState(null);
const [publicToken, setPublicToken] = useState(null);
const [refreshSuccessful, setRefreshSuccessful] = useState(false);
const config = getConfig(linkToken, setPublicToken, setAccountInfo, setRefreshSuccessful);
const {open,ready } = usePlaidLink(config);

useEffect(() => {
    console.log('refresh val changes :', refreshSuccessful)
  if (refreshSuccessful) {
    alert('refresh successful')
     // updateAuthRefreshNeeded(authContext, accountInfo?._id, false);
  }
}, [refreshSuccessful]);

useEffect(() => {
  if (ready) {
   console.log('gonna try to open');
    open();
  }
}, [ready, open]);

const removeLink = async (userId, accountId) => {
  await deleteLink(userId, accountId);
  // authContext.setCurrentUser({
  //   ...authContext?.currentUser,
  //   accounts: [...accounts]
  // });
  navigate('/accounts');
}

const refreshAuthClicked = async (userId, accountId, accessToken) => {
  // not returning accounts rn
  /// let response = await createLink(userId, accountId, accessToken, authContext);
  // setLinkToken(response?.plaidLink?.link_token);
}


const addToRedList = async (accessToken, user,link_id, account_id) => {
  let result = await redListAccount(accessToken, user, link_id, account_id)
  // authContext.setCurrentUser({
  //   ...authContext.currentUser,
  //   accounts: result?.accounts
  // })
  let account =  result?.accounts?.find(account => account?._id === itemId);
  setAccountInfo({...account})
  console.log({result})
}

const addToGreenList = async (accessToken, user, link_id, account_id) => {
  let result = await greenListAccount(accessToken, user, link_id, account_id)
  // authContext.setCurrentUser({
  //   ...authContext.currentUser,
  //   accounts: result?.accounts
  // })
  let account =  result?.accounts?.find(account => account?._id === itemId);
  setAccountInfo({...account})
  console.log({result})
}

const initialTransmission = async (accessToken, user) => {
  let result = await sendTransmission(accessToken, user);
  console.log({result})
}

const fetchAccounts = async (accessToken, user) => {
  let accounts = await getAccounts(accessToken, user)
  if(!accounts?.length) return;
  setAccounts([...accounts])
}

const fetchTransactions = async (accessToken, account_id, user) => {
  console.log({accessToken})
  let transactions = await getTransactions(accessToken, account_id , user)
  console.log({transactions})
  setTransactions([...transactions])
}

 useEffect(() => {
   if(!user || !itemId) return;
   let account = user?.accounts.find(account => account?._id === itemId)
   console.log({account})
   setAccountInfo({...account})
 }, [user, itemId])

  
return (
    <div>
      <h1>Account Details</h1>
      {/* <button onClick={() => generateLink(setLinkToken)}>1. generate link</button><br/> */}
      {/* <button onClick={() => getTransactions(accessData.accessToken, transactions, setTransactions)}>4. get transactions</button><br/> */}
       {/* <input id="accountName" name="accountName" onChange={handleChange} value={accountName} type="text"></input>
      <button onClick={() => saveAccount(publicToken, authContext, accountName)}> Save Account</button><br/> */}
      <p>Access Data: </p>
      <p>...  Token: {accountInfo?.accessToken}</p>
      <p>... Id: {accountInfo?.itemId}</p>
      <p>Link Token: {linkToken}</p>
      <p>public Token: {publicToken}</p>
      <p>Account Name: {accountInfo?.accountName}</p>
      <p>Auth Refresh Required : {accountInfo?.authRefreshRequired ? 'true' : 'false'}</p>
      <p>Ready : {ready ? 'true' : 'false'}</p>

      <br/>
      <button onClick={() => {initialTransmission(accountInfo?.accessToken, user)}}>Send Transmission</button><br/><br/>
      <button onClick={() => {fetchTransactions(accountInfo?.accessToken, accountInfo?._id, user)}}>Get Transactions</button><br/><br/>
      <button onClick={() => {fetchAccounts(accountInfo?.accessToken, user)}}>Get Accounts</button><br/><br/>
      <button onClick={() => {removeLink(user?.id, accountInfo?._id)}}>Remove Link</button>
      <button onClick={() => {refreshAuthClicked(user?.id, accountInfo?._id, accountInfo?.accessToken)}}>Refresh Auth</button>
      <button onClick={() => {
           updateAuthRefreshNeeded(user, accountInfo?._id, true);
        }}>Set Auth Refresh Needed</button>
      {/* <div>{accountInfo?.accounts?.length && accountInfo?.accounts?.map(account => {
        return (<p key={account?.id}>{account?.name}, {account?.subtype}, {account?.id}</p>)
      })}</div>
    */}
      <p>Accounts: </p>
      <div>{accounts?.length && accounts.map(account => {
        return (
          <div>
            {
              accountInfo?.redList.includes(account?.account_id) 
                  ? (<div>
                        <p>{account?.official_name}, {account?.type}, {account?.balance} red</p>
                        <button onClick={() => {
                          addToGreenList(accountInfo?.accessToken, user, accountInfo._id, account?.account_id)}}>
                          greenlist account
                        </button>
                        <br/>
                    </div>)
                  : (<div>
                        <p>{account?.official_name}, {account?.type}, {account?.balance} green</p>
                        <button onClick={() => {
                          addToRedList(accountInfo?.accessToken, user, accountInfo._id, account?.account_id)}}>
                          redlist account
                        </button>
                        <br/>
                  </div>)
            }
          </div>
        )
      })}</div>
      <p>Transactions:</p>
      <div>{transactions?.length && transactions.map(transaction => {
        return (<p>{transaction?.merchant_name}, {transaction.amount}, {transaction.date}</p>)
      })}</div>
    </div>


  )
}

export default AccountDetails 

import { base_url } from ".";

const createNotification = async (args) => {
  const { name, recipients, accounts, user_id, token } = args;

  let URL = `${base_url}/api/notification/create`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      name,
      recipients,
      accounts,
      user_id
    })
  });

  if (!response.ok) {
    throw new Error('unable to create notification')
  }

  return await response.json();
}

const updateNotification = async (args) => {
  const { notification_id, name, recipients, accounts, user_id, token } = args;

  let URL = `${base_url}/api/notification/update`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      notification_id,
      name,
      recipients,
      accounts,
      user_id
    })
  });

  if (!response.ok) {
    alert('unable to create notification')
    return;
  }

  return await response.json();
}

const testNotification = async (args) => {
  const { notification_id, user_id, token } = args;
  let URL = `${base_url}/api/notification/test`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      user_id,
      notification_id
    })
  });

  if (!response.ok) {
    throw new Error('unable to test notification')
  }

  return await response.json();
}
const deleteNotification = async (args) => {
  const { notification_id, user_id, token } = args;

  let URL = `${base_url}/api/notification`;
  const response = await fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      user_id,
      notification_id
    })
  });

  if (!response.ok) {
    throw new Error('unable to delete notification')
  }

  return await response.json();
}

export { createNotification, deleteNotification, updateNotification, testNotification }


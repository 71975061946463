import { toast } from "react-hot-toast";
import { createRecipient, deleteRecipient, updateRecipient } from "../network/recipients"

const handleCreateRecipient = (args) => {
  const { name, cell, email, user_id, token, dispatch } = args;

  Promise.resolve().then(async () => {
    const { user, message } = await createRecipient({ name, cell, email, user_id, token });
    dispatch({
      type: 'updated',
      user
    });
    toast.success(message)
  }).catch(error => {
    toast.error(error)
  })
}

const handleUpdateRecipient = (args) => {
  const { recipient_id, name, cell, email, user_id, token, dispatch } = args;

  Promise.resolve().then(async () => {
    const { user, message } = await updateRecipient({ recipient_id, name, cell, email, user_id, token });
    dispatch({
      type: 'updated',
      user
    });
    alert(message)
  }).catch(error => {
    alert(error);
  })
}

const handleDeleteRecipient = (args) => {
  const { recipient_id, user_id, token, dispatch } = args;
  Promise.resolve().then(async () => {
    const { user, message } = await deleteRecipient({ recipient_id, user_id, token });
    toast.success(message)
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    toast.error(error.message)
  })
}

export { handleCreateRecipient, handleDeleteRecipient, handleUpdateRecipient }
import { createContext, useContext, useReducer, useEffect } from 'react';
export const UserContext = createContext(null);
export const UserDispatchContext = createContext(null);


// const getInitialUser = () => {
//   const user = localStorage.getItem('user') || null;
//   console.log('getInitialUser: ', user)
//   return user
// }

// const initialUser = getInitialUser();

export function UserProvider({ children }) {
  const storedUser = localStorage.getItem('user');
  const [user, dispatch] = useReducer(
    userReducer,
    storedUser ? JSON.parse(storedUser) : null
  )

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user])

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}

export function useUserDispatch() {
  return useContext(UserDispatchContext)
}

function userReducer(user, action) {
  console.log('userReducer: ', { user }, { action })
  switch (action?.type) {
    case 'updated': {
      localStorage.setItem('user', JSON.stringify({ ...user, ...action?.user }));
      return {
        ...user,
        ...action?.user
      }
    }
    case 'deleted': {
      return null;
    }
    default: {
      throw Error('Unkown action: ', action?.type)
    }
  }
}
